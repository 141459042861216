import React from 'react'

function WalletIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      id="Glyph"
      height="512"
      viewBox="0 0 32 32"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="XMLID_436_"
        d="m30 24v2.5c0 1.379-1.122 2.5-2.5 2.5h-21.5c-2.206 0-4-1.794-4-4 0 0 0-16.985 0-17 0-2.206 1.794-4 4-4h18.5c.829 0 1.5.672 1.5 1.5s-.671 1.5-1.5 1.5h-18.5c-.551 0-1 .448-1 1s.449 1 1 1h21.5c1.378 0 2.5 1.121 2.5 2.5v2.5h-5c-2.757 0-5 2.243-5 5s2.243 5 5 5z"
      />
      <path id="XMLID_438_" d="m30 16v6h-5c-1.657 0-3-1.343-3-3s1.343-3 3-3z" />
    </svg>
  )
}

export default WalletIcon
