import { useUserPricePlans } from 'modules/digital-product/price-plans/hooks/use-user-price-plans'
import {
  PricePlanEditInterface,
  PricePlanId,
} from 'modules/digital-product/price-plans/types/price-plan-interface'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { PrimaryButton } from 'shared/components/button'
import FormInput from 'shared/components/form-input'
import { FieldErrorAndDescription } from 'shared/components/form/field-error-and-description'
import { Hint } from 'shared/components/hint'
import { CurrencyEnum } from 'shared/enums/currency-enum'
import { BadRequest } from 'shared/errors/bad-request'
import { InternalError } from 'shared/errors/internal-error'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import CloseIcon from 'shared/icons/close-icon'
import SaveIcon from 'shared/icons/save-icon'

interface EditPricePlanFormProps {
  onEditPricePlan: (value: PricePlanEditInterface) => Promise<void>
  closeForm: () => void
  editPricePlanErrors: PricePlanEditInterface
  pricePlanId?: PricePlanId
  currency: CurrencyEnum
}

const defaultValues: PricePlanEditInterface = {
  name: '',
  innerName: '',
  statementDescriptor: '',
}

function EditPricePlanForm({
  onEditPricePlan,
  closeForm,
  editPricePlanErrors,
  pricePlanId,
  currency,
}: EditPricePlanFormProps) {
  const { t } = useLocoTranslation()
  const [tempState, setTempState] = useState(defaultValues)
  const [isFetching, setIsFetching] = useState(false)
  const [error, setError] = useState('')

  const { userPricePlans } = useUserPricePlans(currency)

  const handleConfirm = async () => {
    try {
      setError('')
      setIsFetching(true)
      await onEditPricePlan(tempState)
      setIsFetching(false)
      closeForm()
    } catch (e) {
      setIsFetching(false)
      if (e instanceof BadRequest) {
        if (e.errors.common) {
          setError(e.errors.common.join(''))
        }
      } else if (e instanceof InternalError) {
        setError(t('core.error.internal_error_message'))
      } else {
        toast.error(t('global.error'))
      }
    }
  }

  useEffect(() => {
    const pricePlan = userPricePlans?.find(pricePlan => pricePlan.id === pricePlanId)
    if (pricePlan) {
      setTempState(prev => {
        return {
          ...prev,
          name: pricePlan.name,
          innerName: pricePlan.innerName,
          statementDescriptor: pricePlan.statementDescriptor,
        }
      })
    }
  }, [pricePlanId, userPricePlans])

  return (
    <div className="flex flex-col gap-5 relative rounded-lg border-gray/30 border py-3 px-5">
      <CloseIcon
        className={`cursor-pointer ${
          isFetching && 'fill-gray pointer-events-none'
        } absolute lg:top-2 top-1 lg:right-2 right-1`}
        onClick={() => closeForm()}
      />
      <FormInput
        required
        isPreFetching={!pricePlanId}
        label={t('dashboard.funnel.configuration.common.price_plan.name_label')}
        labelClassName={'lg:text-[15px] text-sm text-darkblue font-medium'}
        value={tempState.name}
        onChange={e =>
          setTempState(prev => {
            return { ...prev, name: e.target.value }
          })
        }
        maxLength={256}
        error={editPricePlanErrors.name}
      />
      <FormInput
        required
        isPreFetching={!pricePlanId}
        label={t('dashboard.funnel.configuration.common.price_plan.inner_name_label')}
        labelClassName={'lg:text-[15px] text-sm text-darkblue font-medium'}
        value={tempState.innerName}
        onChange={e =>
          setTempState(prev => {
            return { ...prev, innerName: e.target.value }
          })
        }
        maxLength={256}
        error={editPricePlanErrors.innerName}
      />
      <FormInput
        required
        isPreFetching={!pricePlanId}
        label={
          <Hint
            label={t(
              'dashboard.funnel.configuration.common.price_plan.statement_descriptor_popover',
            )}
          >
            {t('dashboard.funnel.configuration.common.price_plan.statement_descriptor')}
          </Hint>
        }
        labelClassName={'lg:text-[15px] text-sm text-darkblue font-medium'}
        value={tempState.statementDescriptor}
        onChange={e =>
          setTempState(prev => {
            return { ...prev, statementDescriptor: e.target.value }
          })
        }
        maxLength={256}
        error={editPricePlanErrors.statementDescriptor}
      />
      <div className="flex ">
        <PrimaryButton
          disabled={!tempState.name || !tempState.innerName || !tempState.statementDescriptor}
          width="large"
          onClick={e => {
            e.preventDefault()
            handleConfirm()
          }}
          isFetching={isFetching}
          type="submit"
        >
          <SaveIcon />
          {t('global.save')}
        </PrimaryButton>
      </div>
      <FieldErrorAndDescription error={error} errorClassName={'text-center mt-2'} />
    </div>
  )
}

export default EditPricePlanForm
