import { pricePlanTypeLocoKeys } from 'modules/digital-product/price-plans/constants/price-plan-type-loco-keys'
import { PricePlanTypeEnum } from 'modules/digital-product/price-plans/enums/price-plan-type-enum'
import { PricePlanType } from 'modules/digital-product/price-plans/types/price-plan-interface'
import React from 'react'
import { Tooltip } from 'shared/components/tooltip'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { currencyFormat } from 'shared/utils/currency-format'

interface PricePlanTooltipProps {
  pricePlan: PricePlanType
  label: string | JSX.Element
}

function PricePlanTooltip({ pricePlan, label }: PricePlanTooltipProps) {
  const { t } = useLocoTranslation()
  const { user } = useUser()
  return (
    <Tooltip
      mode="hover"
      position="left"
      className="whitespace-normal"
      wrapperClassName={'w-full'}
      portal={true}
      label={
        <div className="flex flex-col items-start gap-2 font-inter">
          <p>
            <span className="font-bold text-darkblue">
              {t('dashboard.funnel.configuration.common.price_plan.name_label')}:{' '}
            </span>
            {pricePlan.name}
          </p>
          <p>
            <span className="font-bold text-darkblue">
              {t('dashboard.funnel.configuration.common.price_plan.statement_descriptor')}:{' '}
            </span>
            {pricePlan.statementDescriptor}
          </p>
          <p>
            <span className="font-bold text-darkblue">
              {t('dashboard.funnel.configuration.common.price_plan.type')}:{' '}
            </span>
            {t(pricePlanTypeLocoKeys[pricePlan.type])}
          </p>
          {pricePlan.type === PricePlanTypeEnum.limited_subscription && (
            <p>
              <span className="font-bold text-darkblue">
                {t(
                  'dashboard.funnel.configuration.common.price_plan.subscription_plan.limit_of_payments',
                )}
                :{' '}
              </span>
              {pricePlan.recurringOptions.limitOfPayments}
            </p>
          )}
          {pricePlan.recurringOptions && pricePlan.recurringOptions.trialPeriod > 0 && (
            <p>
              <span className="font-bold text-darkblue">
                {`${t(
                  'dashboard.funnel.configuration.common.price_plan.subscription_plan.trial_period',
                )} : `}
              </span>
              {t(
                `dashboard.funnel.configuration.common.price_plan.period.${pricePlan.recurringOptions.trialInterval}`,
                {
                  count: pricePlan.recurringOptions.trialPeriod,
                },
              )}
            </p>
          )}
          {pricePlan.type === PricePlanTypeEnum.one_shot ? (
            <p>
              <span className="font-bold text-darkblue">
                {t('dashboard.funnel.configuration.common.price_plan.direct_charge_amount')}:{' '}
              </span>
              {`${currencyFormat(
                pricePlan.amount / 100,
                pricePlan.currency,
                user?.dashboardLocale,
              )}`}
            </p>
          ) : (
            <p>
              <span className="font-bold text-darkblue">
                {t('dashboard.funnel.configuration.common.price_plan.direct_charge_amount')}:{' '}
              </span>
              {pricePlan.amount &&
                `${currencyFormat(
                  pricePlan.amount / 100,
                  pricePlan.currency,
                  user?.dashboardLocale,
                )} ${t(
                  'dashboard.funnel.configuration.common.price_plan.subscription_plan.interval_label',
                )} ${t(
                  `dashboard.funnel.configuration.common.price_plan.period.${pricePlan.recurringOptions.interval}`,
                  {
                    count: pricePlan.recurringOptions.intervalCount,
                  },
                )}`}
            </p>
          )}
        </div>
      }
    >
      {label}
    </Tooltip>
  )
}

export default PricePlanTooltip
