import { DIGITAL_PRODUCT_API } from 'modules/digital-product/api'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useSWR from 'swr'
import { PricePlanCountInterface, PricePlanId } from '../types/price-plan-interface'

export function usePricePlansCount(shouldFetch?: boolean, pricePlanId?: PricePlanId) {
  const { fetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    PricePlanCountInterface
  >({ method: RequestMethodsEnum.get })

  return useSWR(() => {
    if (shouldFetch && pricePlanId) {
      return `${DIGITAL_PRODUCT_API}/count/by-price-plan/${pricePlanId}`
    }
    return null
  }, fetcher)
}
