import { pricePlanTypeLocoKeys } from 'modules/digital-product/price-plans/constants/price-plan-type-loco-keys'
import { PricePlanTypeEnum } from 'modules/digital-product/price-plans/enums/price-plan-type-enum'
import { PricePlanType } from 'modules/digital-product/price-plans/types/price-plan-interface'
import React from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { currencyFormat } from 'shared/utils/currency-format'

interface PricePlanDescriptionProps {
  pricePlan: PricePlanType
}

function PricePlanDescription({ pricePlan }: PricePlanDescriptionProps) {
  const { t } = useLocoTranslation()
  const { user } = useUser()
  return (
    <div className="flex flex-col items-start flex-1 gap-2 font-inter rounded-lg border-gray/30 border py-3 px-5">
      <p>
        <span className="font-bold text-darkblue">
          {t('dashboard.funnel.configuration.common.price_plan.name_label')}:{' '}
        </span>
        {pricePlan.name}
      </p>
      <p>
        <span className="font-bold text-darkblue">
          {t('dashboard.funnel.configuration.common.price_plan.statement_descriptor')}:{' '}
        </span>
        {pricePlan.statementDescriptor}
      </p>
      <p>
        <span className="font-bold text-darkblue">
          {t('dashboard.funnel.configuration.common.price_plan.type')}:{' '}
        </span>
        {t(pricePlanTypeLocoKeys[pricePlan.type])}
      </p>
      {pricePlan.type === PricePlanTypeEnum.limited_subscription && (
        <p>
          <span className="font-bold text-darkblue">
            {t(
              'dashboard.funnel.configuration.common.price_plan.subscription_plan.limit_of_payments',
            )}
            :{' '}
          </span>
          {pricePlan.recurringOptions.limitOfPayments}
        </p>
      )}
      {pricePlan.recurringOptions &&
        pricePlan.recurringOptions.trialPeriod > 0 &&
        pricePlan.recurringOptions.trialInterval && (
          <p>
            <span className="font-bold text-darkblue">
              {`${t(
                'dashboard.funnel.configuration.common.price_plan.subscription_plan.trial_period',
              )} : `}
            </span>
            {t(
              `dashboard.funnel.configuration.common.price_plan.period.${pricePlan.recurringOptions.trialInterval}`,
              {
                count: pricePlan.recurringOptions.trialPeriod,
              },
            )}
          </p>
        )}
      {pricePlan.type === PricePlanTypeEnum.one_shot ? (
        <p>
          <span className="font-bold text-darkblue">
            {t('dashboard.funnel.configuration.common.price_plan.direct_charge_amount')}:{' '}
          </span>
          {`${currencyFormat(pricePlan.amount / 100, pricePlan.currency, user?.dashboardLocale)}`}
        </p>
      ) : (
        <p>
          <span className="font-bold text-darkblue">
            {t('dashboard.funnel.configuration.common.price_plan.direct_charge_amount')}:{' '}
          </span>
          {pricePlan.amount &&
            `${currencyFormat(
              pricePlan.amount / 100,
              pricePlan.currency,
              user?.dashboardLocale,
            )} ${t(
              'dashboard.funnel.configuration.common.price_plan.subscription_plan.interval_label',
            )} ${t(
              `dashboard.funnel.configuration.common.price_plan.period.${pricePlan.recurringOptions.interval}`,
              {
                count: pricePlan.recurringOptions.intervalCount,
              },
            )}`}
        </p>
      )}
    </div>
  )
}

export default PricePlanDescription
