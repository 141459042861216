import { PRICE_PLANS_API } from 'modules/digital-product/price-plans/api/price-plans-api'
import {
  PricePlanCreateApiInterface,
  PricePlanEditInterface,
  PricePlanId,
  PricePlanType,
} from 'modules/digital-product/price-plans/types/price-plan-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'

export const usePricePlansApi = () => {
  const { fetcher: postFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.post,
    PricePlanType
  >({
    method: RequestMethodsEnum.post,
  })
  const { fetcher: putFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.put,
    PricePlanType
  >({
    method: RequestMethodsEnum.put,
  })

  const { fetcher: deleteFetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.delete>({
    method: RequestMethodsEnum.delete,
  })

  return {
    createPricePlan: (data: PricePlanCreateApiInterface) =>
      postFetcher(`${PRICE_PLANS_API}/create`, data),

    updatePricePlan: (id: PricePlanId, data: PricePlanEditInterface) =>
      putFetcher(`${PRICE_PLANS_API}/${id}`, data),

    deletePricePlan: (id: PricePlanId) => deleteFetcher(`${PRICE_PLANS_API}/${id}`),
  }
}
