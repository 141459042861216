import React from 'react'

function ChangeIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      {...props}
    >
      <path d="M475.312 148.688L379.312 52.688C374.736 48.112 367.872 46.768 361.872 49.216C355.888 51.696 352 57.536 352 64V112H112C85.488 112 64 133.488 64 160C64 186.512 85.488 208 112 208H352V256C352 262.464 355.888 268.304 361.872 270.784C367.888 273.264 374.752 271.872 379.312 267.312L475.312 171.312C481.568 165.072 481.568 154.928 475.312 148.688Z" />
      <path d="M400 304H160V256C160 249.536 156.112 243.696 150.128 241.216C144.144 238.736 137.28 240.096 132.688 244.688L36.6881 340.688C30.4481 346.928 30.4481 357.072 36.6881 363.312L132.688 459.312C137.248 463.872 144.112 465.264 150.128 462.784C156.112 460.304 160 454.464 160 448V400H400C426.512 400 448 378.512 448 352C448 325.488 426.512 304 400 304Z" />
    </svg>
  )
}

export default ChangeIcon
