import React from 'react'

function DragIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      fill="#D9D9D9"
      width="15"
      height="25"
      viewBox="0 0 15 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="2.5" cy="2.4942" r="2.5" />
      <circle cx="2.5" cy="12.4942" r="2.5" />
      <circle cx="2.5" cy="22.4942" r="2.5" />
      <circle cx="12.5" cy="2.4942" r="2.5" />
      <circle cx="12.5" cy="12.4942" r="2.5" />
      <circle cx="12.5" cy="22.4942" r="2.5" />
    </svg>
  )
}

export default DragIcon
