export enum DigitalResourcesTypeEnum {
  membership_course = 'membership_course',
  membership_course_bundle = 'membership_course_bundle',
  systemeio_plan = 'systemeio_plan',
  systemeio_tag = 'systemeio_tag',
  community_access = 'community_access',
  downloadable_file = 'downloadable_file',
}

export const DigitalResourcesTypeTitles: Record<DigitalResourcesTypeEnum, string> = {
  [DigitalResourcesTypeEnum.membership_course]:
    'dashboard.funnel.configuration.common.courses.title',
  [DigitalResourcesTypeEnum.membership_course_bundle]:
    'dashboard.funnel.configuration.common.course_bundles.title',
  [DigitalResourcesTypeEnum.systemeio_plan]:
    'dashboard.funnel.configuration.common.user_plans.title',
  [DigitalResourcesTypeEnum.systemeio_tag]: 'dashboard.funnel.configuration.common.tags.title',
  [DigitalResourcesTypeEnum.community_access]:
    'dashboard.funnel.configuration.common.communities.title',
  [DigitalResourcesTypeEnum.downloadable_file]:
    'dashboard.digital_product.resources.download.title',
}
