import { arrayMove } from '@dnd-kit/sortable'
import AddPricePlanForm from 'modules/digital-product/price-plans/components/add-price-plan-form'
import CreatePricePlanForm from 'modules/digital-product/price-plans/components/create-price-plan-form'
import EditPricePlanForm from 'modules/digital-product/price-plans/components/edit-price-plan-form'
import PricePlanBase from 'modules/digital-product/price-plans/components/price-plan-base'
import PricePlanItem from 'modules/digital-product/price-plans/components/price-plan-item'
import RemovePricePlanConfirmation from 'modules/digital-product/price-plans/components/remove-price-plan-confirmation'
import { PricePlanRemoveTypeEnum } from 'modules/digital-product/price-plans/enums/price-plan-remove-type-enum'
import { usePricePlansApi } from 'modules/digital-product/price-plans/hooks/use-price-plans-api'
import { useUserPricePlans } from 'modules/digital-product/price-plans/hooks/use-user-price-plans'
import {
  PricePlanCreateErrorsInterface,
  PricePlanCreateInterface,
  PricePlanEditInterface,
  PricePlanId,
  PricePlanType,
} from 'modules/digital-product/price-plans/types/price-plan-interface'
import { DigitalProductErrorInterface } from 'modules/digital-product/types/digital-product-interface'
import { AddItemIcon } from 'modules/funnels/funnel/components/offer-settings/add-item-icon'
import React, { useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { FieldErrorAndDescription } from 'shared/components/form/field-error-and-description'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { SectionMessage } from 'shared/components/section-message'
import Sortable from 'shared/components/sortable'
import { CurrencyEnum } from 'shared/enums/currency-enum'
import { PaymentMethodEnum } from 'shared/enums/payment-method-enum'
import { BadRequest } from 'shared/errors/bad-request'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { fetchWithErrors } from 'shared/utils/fetch-with-errors'

const defaultCreateErrors: PricePlanCreateErrorsInterface = {
  name: '',
  innerName: '',
  statementDescriptor: '',
  type: '',
  amount: '',
  statementDescriptorKatakana: '',
  recurringOptions: {
    interval: '',
    intervalCount: '',
    trialPeriod: '',
    trialInterval: '',
    limitOfPayments: '',
  },
}

const defaultEditErrors = {
  name: '',
  innerName: '',
  statementDescriptor: '',
}

type Props = {
  currency: CurrencyEnum
  pricePlans: PricePlanType[]
  errors: DigitalProductErrorInterface['pricePlans']
  maxPricePlans: number
  paymentMethods: PaymentMethodEnum[]
  mutatePricePlans?: (pricePlans: PricePlanType[]) => Promise<void>
  onChanePricePlans: (pricePlans: PricePlanType[]) => void
}

function PricePlans({
  pricePlans,
  onChanePricePlans,
  maxPricePlans,
  mutatePricePlans,
  errors,
  paymentMethods,
  currency,
}: Props) {
  const [isAddFormOpen, setIsAddFormOpen] = useState(false)
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false)

  const [pricePlanIdToDelete, setPricePlanIdToDelete] = useState<null | PricePlanId>(null)

  const [pricePlanIdToEdit, setPricePlanIdToEdit] = useState<null | PricePlanId>(null)

  const [pricePlanIdToReplace, setPricePlanIdToReplace] = useState<null | PricePlanId>(null)

  const [createErrors, setCreateErrors] = useState(defaultCreateErrors)
  const [editPricePlanErrors, setEditPricePlanErrors] = useState(defaultEditErrors)

  const [isCreateFormForReplaceOpen, setIsCreateFormForReplaceOpen] = useState(false)

  const { mutateUserPricePlans } = useUserPricePlans(currency)

  const { t } = useLocoTranslation()

  const { createPricePlan, updatePricePlan, deletePricePlan } = usePricePlansApi()
  const createdPricePlanId = useRef<number | undefined>()

  const hasPaymentMethods = paymentMethods.length > 0

  const allowMorePricePlans = hasPaymentMethods && pricePlans.length < maxPricePlans

  const handleCreatePricePlan = async (
    createPricePlanData: PricePlanCreateInterface,
    oldPricePlanId?: PricePlanId,
  ) => {
    try {
      setCreateErrors(defaultCreateErrors)
      const responseData = await createPricePlan({
        ...createPricePlanData,
        currency: currency,
      })
      await mutateUserPricePlans(data => data && [...data, responseData], false)
      await handleAddPricePlan(responseData, oldPricePlanId)
    } catch (e) {
      if (e instanceof BadRequest) {
        if (e.errors.fields) {
          const errors = e.errors.fields as unknown as PricePlanCreateErrorsInterface
          setCreateErrors(prev => ({ ...prev, ...errors }))
        }
      }
      throw e
    }
  }

  const handleAddPricePlan = async (newPricePlan: PricePlanType, oldPricePlanId?: PricePlanId) => {
    try {
      if (oldPricePlanId) {
        const newPricePlans = pricePlans.map(pricePlan =>
          pricePlan.id === oldPricePlanId ? newPricePlan : pricePlan,
        )
        onChanePricePlans(newPricePlans)
      } else {
        const newPricePlans = [...pricePlans, newPricePlan]
        onChanePricePlans(newPricePlans)
      }
      closeAddForm()
    } catch {
      toast.error(t('global.error'))
    }
  }

  const handleRemovePricePlan = async (removeType: PricePlanRemoveTypeEnum) => {
    if (!pricePlanIdToDelete) return
    const updatedPricePlans = pricePlans.filter(pricePlan => pricePlan.id !== pricePlanIdToDelete)
    try {
      onChanePricePlans(updatedPricePlans)
      if (removeType === PricePlanRemoveTypeEnum.from_account) {
        await deletePricePlan(pricePlanIdToDelete)
        await mutateUserPricePlans(data => {
          if (data) {
            return data.filter(pricePlan => pricePlan.id !== pricePlanIdToDelete)
          }
        }, false)
        if (mutatePricePlans) {
          await mutatePricePlans(updatedPricePlans)
        }
      }
    } catch {
      toast.error(t('global.error'))
    }
  }

  const handleEditPricePlan = async (updatedPricePlanData: PricePlanEditInterface) => {
    if (!pricePlanIdToEdit) return
    await fetchWithErrors(async () => {
      const responseData = await updatePricePlan(pricePlanIdToEdit, updatedPricePlanData)
      await mutateUserPricePlans(data => {
        if (data) {
          return data.map(pricePlan => {
            if (pricePlan.id === responseData.id) {
              return { ...pricePlan }
            }
            return pricePlan
          })
        }
      }, false)

      const updatedPricePlans = pricePlans.map(pricePlan => {
        if (pricePlan.id === pricePlanIdToEdit) {
          return responseData
        }
        return pricePlan
      })
      onChanePricePlans(updatedPricePlans)
      if (mutatePricePlans) {
        await mutatePricePlans(updatedPricePlans)
      }
      toast.success(t('global.changes_saved'))
    }, setEditPricePlanErrors)
  }

  const openCreateForm = () => {
    setIsAddFormOpen(false)
    setIsCreateFormOpen(true)
  }

  const openAddForm = (value?: number) => {
    setIsAddFormOpen(true)
    setIsCreateFormOpen(false)
    if (value) createdPricePlanId.current = value
  }

  const closeAddForm = () => {
    setIsAddFormOpen(false)
  }

  const closeCreateForm = () => {
    setIsCreateFormOpen(false)
  }

  const getPricePlanJSX = (pricePlanData: PricePlanType) => {
    if (pricePlanData.id === pricePlanIdToEdit) {
      return (
        <EditPricePlanForm
          currency={currency}
          key={pricePlanData.id}
          onEditPricePlan={handleEditPricePlan}
          closeForm={() => setPricePlanIdToEdit(null)}
          editPricePlanErrors={editPricePlanErrors}
          pricePlanId={pricePlanIdToEdit}
        />
      )
    }
    if (pricePlanData.id === pricePlanIdToReplace) {
      if (isCreateFormForReplaceOpen) {
        return (
          <CreatePricePlanForm
            key={pricePlanData.id}
            onCreatePricePlan={data => handleCreatePricePlan(data, pricePlanIdToReplace)}
            createErrors={createErrors}
            currency={currency}
            closeForm={() => {
              setPricePlanIdToReplace(null)
              setIsCreateFormForReplaceOpen(false)
            }}
            openAddForm={() => setIsCreateFormForReplaceOpen(false)}
          />
        )
      } else {
        return (
          <AddPricePlanForm
            productPricePlans={pricePlans}
            key={pricePlanData.id}
            currency={currency}
            onAddPricePlan={pricePlan => handleAddPricePlan(pricePlan, pricePlanIdToReplace)}
            closeForm={() => setPricePlanIdToReplace(null)}
            openCreateForm={() => setIsCreateFormForReplaceOpen(true)}
          />
        )
      }
    }
    return (
      <PricePlanItem
        key={pricePlanData.id}
        pricePlan={pricePlanData}
        onChange={() => {
          setPricePlanIdToReplace(pricePlanData.id)
        }}
        onEdit={() => {
          setPricePlanIdToEdit(pricePlanData.id)
        }}
        onDelete={() => setPricePlanIdToDelete(pricePlanData.id)}
      />
    )
  }

  const onMove = async (activeIndex: number, overIndex: number) => {
    const reorderedPricePlans = arrayMove(pricePlans, activeIndex, overIndex)
    onChanePricePlans(reorderedPricePlans)
  }

  return (
    <>
      <PricePlanBase
        additionalChild={
          allowMorePricePlans && (
            <AddItemIcon
              onClick={() => setIsAddFormOpen(true)}
              caption={t('dashboard.funnel.configuration.offer.add_price_plan')}
            />
          )
        }
      >
        <>
          {!hasPaymentMethods && (
            <SectionMessage className={'mt-2'}>
              {t('dashboard.funnel.configuration.common.price_plan.payment_gateways_requirement')}
              <LinkWithoutPrefetch
                className={'primary-link truncate text-start text-sm font-inter font-normal'}
                href={'/profile/payment-gateways'}
              >
                {t(
                  'dashboard.funnel.configuration.common.price_plan.payment_gateways_requirement_link',
                )}
              </LinkWithoutPrefetch>
            </SectionMessage>
          )}
          {isAddFormOpen && (
            <AddPricePlanForm
              productPricePlans={pricePlans}
              onAddPricePlan={handleAddPricePlan}
              currency={currency}
              closeForm={closeAddForm}
              openCreateForm={openCreateForm}
            />
          )}
          {isCreateFormOpen && (
            <CreatePricePlanForm
              onCreatePricePlan={handleCreatePricePlan}
              createErrors={createErrors}
              currency={currency}
              closeForm={closeCreateForm}
              openAddForm={openAddForm}
            />
          )}
          <Sortable
            draggable={getPricePlanJSX}
            items={pricePlans}
            onMove={(_, activeIndex, overIndex) => onMove(activeIndex, overIndex)}
          >
            {pricePlans.map(getPricePlanJSX)}
          </Sortable>
          <FieldErrorAndDescription error={errors.join(', ')} errorClassName={'text-center'} />
        </>
      </PricePlanBase>
      <RemovePricePlanConfirmation
        currency={currency}
        pricePlanId={pricePlanIdToDelete}
        onRemove={handleRemovePricePlan}
        afterLeave={() => setPricePlanIdToDelete(null)}
      />
    </>
  )
}

export default PricePlans
