import { pricePlanTypeLocoKeys } from 'modules/digital-product/price-plans/constants/price-plan-type-loco-keys'
import {
  PricePlanRemoveTypeDataInterface,
  PricePlanType,
} from 'modules/digital-product/price-plans/types/price-plan-interface'
import {
  SubscriptionIntervalTitles,
  SubscriptionIntervalsEnum,
} from 'modules/payment/transactions/enums/subscription-intervals-enum'
import { TLocoType } from 'shared/hooks/use-loco-translation'
import PricePlanTooltip from '../components/price-plan-tooltip'
import { PricePlanRemoveTypeEnum } from '../enums/price-plan-remove-type-enum'
import { PricePlanTypeEnum } from '../enums/price-plan-type-enum'

export interface ListingDataInterface {
  id: number
  caption: string
  customCaption?: JSX.Element
}

interface PricePlanListingDataInterface {
  id: PricePlanTypeEnum
  caption: string
}

export const getPricePlansTypesData = (t: TLocoType): PricePlanListingDataInterface[] =>
  Object.values(PricePlanTypeEnum).map(type => ({
    id: type,
    caption: t(pricePlanTypeLocoKeys[type]),
  }))

export const getAvailablePricePlansData = ({
  pricePlansList,
  type,
  existingPricePlans,
}: {
  pricePlansList?: PricePlanType[]
  type?: PricePlanTypeEnum
  existingPricePlans?: PricePlanType[]
}) => {
  return pricePlansList
    ?.filter(pricePlan => {
      if (existingPricePlans?.find(existingPricePlan => existingPricePlan.id === pricePlan.id)) {
        return false
      }
      if (!type) {
        return true
      }
      return pricePlan.type === type
    })
    .map(pricePlan => {
      return {
        id: pricePlan.id,
        caption: pricePlan.innerName,
        customCaption: (
          <PricePlanTooltip
            pricePlan={pricePlan}
            label={
              <div className="flex flex-row gap-1">
                <span className="truncate">{pricePlan.innerName}</span>
              </div>
            }
          />
        ),
      }
    }, [] as ListingDataInterface[])
}

export const isLimitedSubscription = (type?: PricePlanTypeEnum) =>
  type === PricePlanTypeEnum.limited_subscription

export const getSubscriptionIntervalData = (t: TLocoType) =>
  Object.keys(SubscriptionIntervalTitles).map(interval => {
    const type = interval as keyof typeof SubscriptionIntervalsEnum
    return {
      id: type,
      caption: t(SubscriptionIntervalTitles[type]),
    }
  }) as { id: SubscriptionIntervalsEnum; caption: string }[]

export const getPricePlanRemoveTypeData = (t: TLocoType): PricePlanRemoveTypeDataInterface[] => [
  {
    id: PricePlanRemoveTypeEnum.from_offer,
    caption: t('dashboard.funnel.configuration.common.price_plan.remove_type.from_offer'),
  },
  {
    id: PricePlanRemoveTypeEnum.from_account,
    caption: t('dashboard.funnel.configuration.common.price_plan.remove_type.from_account'),
  },
]
