import { DeleteConfirmationLoader } from 'modules/digital-product/components/delete-confirmation-loader'
import PricePlanDescription from 'modules/digital-product/price-plans/components/price-plan-description'
import { PricePlanTypeEnum } from 'modules/digital-product/price-plans/enums/price-plan-type-enum'
import { usePricePlansApi } from 'modules/digital-product/price-plans/hooks/use-price-plans-api'
import { usePricePlansCount } from 'modules/digital-product/price-plans/hooks/use-price-plans-count'
import { useUserPricePlans } from 'modules/digital-product/price-plans/hooks/use-user-price-plans'
import {
  PricePlanId,
  PricePlanType,
} from 'modules/digital-product/price-plans/types/price-plan-interface'
import {
  ListingDataInterface,
  getAvailablePricePlansData,
  getPricePlansTypesData,
} from 'modules/digital-product/price-plans/utils/price-plans-utils'
import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { PrimaryButton } from 'shared/components/button'
import ButtonLikeLink from 'shared/components/button-like-link'
import Confirmation from 'shared/components/confirmation-modal'
import FormSelect, { FormSelectProps } from 'shared/components/form-select'
import { FieldErrorAndDescription } from 'shared/components/form/field-error-and-description'
import { CurrencyEnum } from 'shared/enums/currency-enum'
import { BadRequest } from 'shared/errors/bad-request'
import { InternalError } from 'shared/errors/internal-error'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import AddIcon from 'shared/icons/add-icon'
import BinIcon from 'shared/icons/bin-icon'
import CloseIcon from 'shared/icons/close-icon'

interface AddPricePlanProps {
  productPricePlans: PricePlanType[]
  onAddPricePlan: (value: PricePlanType) => Promise<void>
  openCreateForm: () => void
  closeForm: () => void
  currency: CurrencyEnum
}

function AddPricePlan({
  productPricePlans,
  onAddPricePlan,
  openCreateForm,
  closeForm,
  currency,
}: AddPricePlanProps) {
  const formSelectRef = useRef<NonNullable<FormSelectProps['formSelectRef']>['current']>(null)
  const { t } = useLocoTranslation()
  const [pricePlanId, setPricePlanId] = useState<PricePlanId>()
  const [isFetching, setIsFetching] = useState(false)
  const [error, setError] = useState('')

  const [type, setType] = useState<PricePlanTypeEnum>(PricePlanTypeEnum.one_shot)

  const [pricePlanToDelete, setPricePlanToDelete] = useState<null | ListingDataInterface>(null)
  const [opened, setOpened] = useState(false)

  const { data: countData } = usePricePlansCount(opened, pricePlanToDelete?.id)

  const { userPricePlans, mutateUserPricePlans } = useUserPricePlans(currency)

  const [availablePricePlansData, setAvailablePricePlansData] = useState(
    getAvailablePricePlansData({
      pricePlansList: userPricePlans,
      existingPricePlans: productPricePlans,
      type,
    }),
  )

  useEffect(() => {
    if (userPricePlans) {
      setAvailablePricePlansData(
        getAvailablePricePlansData({
          pricePlansList: userPricePlans,
          existingPricePlans: productPricePlans,
          type,
        }),
      )
    }
  }, [userPricePlans, type, productPricePlans])

  const handleConfirm = async () => {
    try {
      const pricePlan = userPricePlans?.find(el => el.id === pricePlanId)
      if (!pricePlan) return
      setError('')
      setIsFetching(true)
      await onAddPricePlan(pricePlan)
      setIsFetching(false)
    } catch (e) {
      setIsFetching(false)
      if (e instanceof BadRequest) {
        if (e.errors.common) {
          setError(e.errors.common.join(''))
        }
      } else if (e instanceof InternalError) {
        setError(t('core.error.internal_error_message'))
      } else {
        toast.error(t('global.error'))
      }
    }
  }

  const selectedPricePlan = userPricePlans?.find(el => el.id === pricePlanId)

  const { deletePricePlan } = usePricePlansApi()

  const handleRemovePricePlan = async () => {
    if (pricePlanToDelete) {
      try {
        await deletePricePlan(pricePlanToDelete.id)
        await mutateUserPricePlans(data => {
          if (data) {
            return data.filter(pricePlan => pricePlan.id !== pricePlanToDelete.id)
          }
        }, false)
        setPricePlanId(undefined)
        setPricePlanToDelete(null)
      } catch (error) {}
    }
  }

  return (
    <>
      <div className="flex flex-col gap-5 relative rounded-lg border-gray/30 border py-3 px-5">
        <CloseIcon
          className={`cursor-pointer ${
            isFetching && 'fill-gray pointer-events-none'
          } absolute lg:top-2 top-1 lg:right-2 right-1`}
          onClick={() => closeForm()}
        />
        <FormSelect
          label={t('dashboard.funnel.configuration.common.price_plan.type_label')}
          data={getPricePlansTypesData(t)}
          withoutCloseIcon
          onChange={type => {
            setType(type)
            setPricePlanId(undefined)
          }}
          value={type}
        />
        <FormSelect
          formSelectRef={formSelectRef}
          label={t('dashboard.funnel.configuration.common.price_plan.choose_existing')}
          data={availablePricePlansData}
          onChange={setPricePlanId}
          optionIconRenderer={data => {
            if (data) {
              return (
                <BinIcon
                  className="w-4 h-4 group hover:fill-red"
                  onClick={e => {
                    e.stopPropagation()
                    formSelectRef.current?.buttonRef?.current?.click()
                    setPricePlanToDelete(data)
                    setOpened(true)
                  }}
                />
              )
            }
          }}
          value={pricePlanId}
        />
        {selectedPricePlan && <PricePlanDescription pricePlan={selectedPricePlan} />}
        <div
          className={
            'flex lg:flex-row flex-col w-full lg:text-[15px] text-sm text-darkblue font-medium gap-2'
          }
        >
          <span>{t('dashboard.funnel.configuration.offer.add_price_plan_footer')}</span>
          <ButtonLikeLink
            onClick={() => {
              openCreateForm()
            }}
          >
            {t('dashboard.funnel.configuration.offer.add_price_plan_footer_link')}
          </ButtonLikeLink>
        </div>
        <div className="flex">
          <PrimaryButton
            disabled={!pricePlanId}
            width="large"
            onClick={e => {
              e.preventDefault()
              handleConfirm()
            }}
            isFetching={isFetching}
            type="submit"
          >
            <AddIcon />
            {t('global.save')}
          </PrimaryButton>
        </div>
        <FieldErrorAndDescription error={error} errorClassName={'text-center mt-2'} />
      </div>
      <Confirmation
        opened={opened}
        onClose={() => setOpened(false)}
        onConfirm={handleRemovePricePlan}
        confirmationContent={
          <>
            {countData ? (
              <div className="text-darkblue text-base">
                {t('dashboard.offer.digital_product.price_plans.remove_description_with_count', {
                  pricePlanName: pricePlanToDelete?.caption,
                  numberOfSteps: countData.count,
                })}
              </div>
            ) : (
              <DeleteConfirmationLoader />
            )}
          </>
        }
      />
    </>
  )
}

export default AddPricePlan
