import { OFFER_API } from 'modules/digital-product/api'
import {
  OfferFormEditType,
  OfferFormUpdateType,
  OfferFormViewInterface,
} from 'modules/funnels/funnel/components/step-configuration-tab/offer-form-configuration-tab/types/offer-form-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { useFetcherWithAbort } from 'shared/hooks/use-fetcher-with-abort'

const defaultOfferValue: OfferFormEditType = {
  saleLimitFunnelStepRedirect: null,
  affiliateCommission: 0,
  bumps: [],
  coupons: [],
  digitalProduct: null,
  payoutDelay: 0,
  product: null,
  saleLimit: null,
}

const defaultOfferValueUpdate: OfferFormUpdateType = {
  ...defaultOfferValue,
  offer: 0,
}

export const useOfferActions = () => {
  const { fetcher: patchFetcher } = useFetcherWithAbort<
    RequestMethodsEnum.patch,
    OfferFormViewInterface['offer']
  >({
    method: RequestMethodsEnum.patch,
  })

  const { fetcher: postFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.post,
    OfferFormViewInterface
  >({
    method: RequestMethodsEnum.post,
  })

  const createOffer = (data: Partial<OfferFormEditType>) =>
    postFetcher(OFFER_API, { ...defaultOfferValue, ...data })

  const updateOffer = (offerId: number, data: Partial<OfferFormUpdateType>) =>
    patchFetcher(`${OFFER_API}/${offerId}/update`, {
      ...defaultOfferValueUpdate,
      ...data,
      offer: offerId,
    })

  return { createOffer, updateOffer }
}
