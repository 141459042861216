import { DigitalProductSourceEnum } from 'modules/digital-product/enums/digital-product-source-enum'
import {
  DigitalProductApiInterface,
  DigitalProductChangeInterface,
} from 'modules/digital-product/types/digital-product-interface'

export const getDigitalProductApiData = (
  digitalProduct: DigitalProductChangeInterface,
  source: DigitalProductSourceEnum,
): DigitalProductApiInterface => ({
  name: digitalProduct.name,
  resources: digitalProduct.resources,
  pricePlans: digitalProduct.pricePlans.map(pricePlan => pricePlan.id),
  confirmationEmail: digitalProduct.confirmationEmail,
  source,
})
