export enum SubscriptionIntervalsEnum {
  day = 'day',
  week = 'week',
  month = 'month',
  'year' = 'year',
}

export const SubscriptionIntervalTitlesPlural = {
  [SubscriptionIntervalsEnum.year]: 'dashboard.name_of_times.years.plural',
  [SubscriptionIntervalsEnum.month]: 'dashboard.name_of_times.months.plural',
  [SubscriptionIntervalsEnum.week]: 'dashboard.name_of_times.weeks.plural',
  [SubscriptionIntervalsEnum.day]: 'dashboard.name_of_times.days.plural',
}

export const SubscriptionIntervalTitles = {
  [SubscriptionIntervalsEnum.year]: 'dashboard.name_of_times.years.one',
  [SubscriptionIntervalsEnum.month]: 'dashboard.name_of_times.months.one',
  [SubscriptionIntervalsEnum.week]: 'dashboard.name_of_times.weeks.one',
  [SubscriptionIntervalsEnum.day]: 'dashboard.name_of_times.days.one',
}
