import process from 'process'
import React from 'react'
import toast from 'react-hot-toast'
import { Loader } from 'shared/components/loader'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import LinkIcon from 'shared/icons/link-icon'
import { copyTextToClipboard } from 'shared/utils/copy-to-clipboard'
import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from './form/field-error-and-description'
import { FieldLabel, FieldLabelProps } from './form/field-label'

export type FormUrlPathInputProps = React.InputHTMLAttributes<HTMLInputElement> &
  FieldLabelProps &
  FieldErrorAndDescriptionProps & {
    domainName?: string
    additionalPath?: string
    copyButtonClassName?: string
    copyIconClassName?: string
    disabled?: boolean
    success?: boolean
    statusIcon?: React.ReactNode
  }

const FormUrlPathInput = ({
  value,
  onChange,
  description,
  error,
  domainName,
  label,
  labelClassName,
  required,
  additionalPath,
  copyButtonClassName,
  copyIconClassName,
  disabled,
  success,
  statusIcon,
  ...rest
}: FormUrlPathInputProps) => {
  const { t } = useLocoTranslation()
  const calculatedAdditionalPath = additionalPath ? additionalPath + '/' : ''
  return (
    <label className="text-xs sm:text-sm font-medium flex flex-col gap-1">
      <FieldLabel label={label} required={required} labelClassName={labelClassName} />
      <div className="flex">
        <div className=" bg-gray-200 text-gray-700 border border-y-gray/50 border-l-gray/50 border-r-transparent flex justify-start items-center py-2.5 px-2 sm:px-4 rounded-l-lg whitespace-nowrap md:truncate overflow-scroll md:overflow:hidden hide-scrollbar flex-shrink">
          {domainName ? (
            `${process.env.NEXT_PUBLIC_SIO_HOST_SCHEME}://${domainName}/${calculatedAdditionalPath}`
          ) : (
            <Loader small />
          )}
        </div>
        <input
          className={`min-w-[100px] rounded-none flex-1 w-full text-darkblue main-transition-colors border py-2.5 px-4 focus:border-blue focus:outline-none ${
            error ? 'border-danger' : success ? 'border-green' : 'border-gray/30'
          } `}
          type={'text'}
          value={value}
          onChange={onChange}
          spellCheck={false}
          disabled={disabled}
          {...rest}
        />
        <button
          type={'button'}
          onClick={e => {
            e.preventDefault()
            copyTextToClipboard(
              `${process.env.NEXT_PUBLIC_SIO_HOST_SCHEME}://${
                domainName || 'domainName'
              }/${calculatedAdditionalPath}${value}`,
            )
            toast.success(t('global.copy_clipboard'))
          }}
          className={`${copyButtonClassName} flex justify-center items-center px-2 sm:px-4 py-2.5 rounded-r-lg bg-blue hover:bg-blue-300 focus:outline-none focus-visible:ring-2 ring-blue ring-offset-1`}
        >
          {statusIcon ? (
            statusIcon
          ) : (
            <LinkIcon
              className={`fill-white w-[15px] sm:w-[20px] h-[15px] sm:h-[20px] ${copyIconClassName}`}
            />
          )}
        </button>
      </div>
      <FieldErrorAndDescription description={description} error={error} />
    </label>
  )
}

export default FormUrlPathInput
