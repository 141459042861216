import { DIGITAL_PRODUCT_API } from 'modules/digital-product/api'
import {
  DigitalProductApiInterface,
  DigitalProductId,
  DigitalProductInterface,
} from 'modules/digital-product/types/digital-product-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { useFetcherWithAbort } from 'shared/hooks/use-fetcher-with-abort'

export const useDigitalProductActions = () => {
  const { fetcher: postFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.post,
    DigitalProductInterface
  >({ method: RequestMethodsEnum.post })

  const { fetcher: putFetcher } = useFetcherWithAbort<
    RequestMethodsEnum.put,
    DigitalProductInterface
  >({
    method: RequestMethodsEnum.put,
  })

  const { fetcher: deleteFetcher } = useFetcherWithAbort<RequestMethodsEnum.delete>({
    method: RequestMethodsEnum.delete,
  })

  const onCreate = (digitalProduct: DigitalProductApiInterface) =>
    postFetcher(DIGITAL_PRODUCT_API, digitalProduct)

  const onEdit = (digitalProductId: DigitalProductId, digitalProduct: DigitalProductApiInterface) =>
    putFetcher(`${DIGITAL_PRODUCT_API}/${digitalProductId}/edit`, digitalProduct)

  const onDelete = (digitalProductId: DigitalProductId) =>
    deleteFetcher(`${DIGITAL_PRODUCT_API}/${digitalProductId}`)

  return { onCreate, onEdit, onDelete }
}
