import { useUserPricePlans } from 'modules/digital-product/price-plans/hooks/use-user-price-plans'
import {
  PricePlanId,
  PricePlanType,
} from 'modules/digital-product/price-plans/types/price-plan-interface'
import React, { useEffect, useState } from 'react'
import Confirmation from 'shared/components/confirmation-modal'
import RadioGroup from 'shared/components/radio-group'
import { CurrencyEnum } from 'shared/enums/currency-enum'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import { PricePlanRemoveTypeEnum } from '../enums/price-plan-remove-type-enum'
import { getPricePlanRemoveTypeData } from '../utils/price-plans-utils'

interface RemovePricePlanConfirmationProps {
  pricePlanId: PricePlanId | null
  currency: CurrencyEnum
  onRemove: (removeType: PricePlanRemoveTypeEnum) => Promise<void>
  afterLeave: () => void
}

function RemovePricePlanConfirmation({
  pricePlanId,
  currency,
  onRemove,
  afterLeave,
}: RemovePricePlanConfirmationProps) {
  const { t } = useLocoTranslation()
  const pricePlanRemoveTypeData = getPricePlanRemoveTypeData(t)
  const [removeType, setRemoveType] = useState(pricePlanRemoveTypeData[0].id)
  const [opened, setOpened] = useState(false)

  const { userPricePlans } = useUserPricePlans(currency)

  useEffect(() => {
    setOpened(!!pricePlanId)
  }, [pricePlanId])

  const pricePlan = userPricePlans?.find(pricePlan => pricePlan.id === pricePlanId)

  return (
    <Confirmation
      opened={opened}
      onClose={() => {
        setOpened(false)
      }}
      afterLeave={afterLeave}
      onConfirm={() => onRemove(removeType)}
      confirmationText={t('dashboard.funnel.configuration.common.price_plan.remove_confirmation', {
        pricePlanName: pricePlan?.innerName,
      })}
      confirmationContent={
        <div className="flex justify-start">
          <RadioGroup
            options={pricePlanRemoveTypeData}
            selected={removeType}
            onChange={data => setRemoveType(data)}
            optionsWrapperClassName={'flex-col !items-start'}
          />
        </div>
      }
    />
  )
}

export default RemovePricePlanConfirmation
