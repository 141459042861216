import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { useRef } from 'react'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import {
  FetcherType,
  RequestMethodsEnum,
  UseApiWrapperWithErrorValidationProps,
  methodsWithoutBody,
} from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import { ErrorResponseInterface } from 'shared/types/error-response-content-interface'

export const useFetcherWithAbort = <
  M extends RequestMethodsEnum,
  R = void,
  D = any,
  BadRequestError = ErrorResponseInterface,
>(
  args: UseApiWrapperWithErrorValidationProps<R, M, BadRequestError>,
) => {
  const abortController = useRef<AbortController | null>(null)
  const cancelRequest = () => {
    if (abortController.current) {
      abortController.current.abort()
    }
  }

  const { fetcher, isFetching } = useApiWrapperWithErrorValidation<M, R, D, BadRequestError>(args)
  const abortableFetcher: FetcherType<M, R> = (...fetcherArgs) => {
    cancelRequest()
    abortController.current = new AbortController()

    const withoutBody = methodsWithoutBody.includes(args.method)

    const firstArg = fetcherArgs[0]
    let secondArg = fetcherArgs[1] as (D & AxiosRequestConfig<D>) | undefined
    let thirdArg = fetcherArgs[2] as AxiosRequestConfig<D> | undefined

    if (abortController.current) {
      if (withoutBody) {
        secondArg = (
          secondArg
            ? {
                ...secondArg,
                signal: abortController.current.signal,
              }
            : { signal: abortController.current.signal }
        ) as AxiosRequestConfig<D> & D
      } else {
        thirdArg = thirdArg
          ? {
              ...thirdArg,
              signal: abortController.current.signal,
            }
          : { signal: abortController.current.signal }
      }
    }

    const injectedArgs = [firstArg, secondArg, thirdArg] as unknown as Parameters<AxiosInstance[M]>
    return fetcher(...injectedArgs)
  }

  return { fetcher: abortableFetcher, isFetching, cancelRequest }
}
