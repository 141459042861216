import { useEffect, useState } from 'react'
import FormInput, { InputProps } from 'shared/components/form-input/index'

interface FormInputFloatProps extends Omit<InputProps, 'value' | 'onChange' | 'max' | 'min'> {
  value: number
  onChange: (value: number) => void
  toFixed?: number
  max?: number
  min?: number
}

const FormInputFloat = ({
  value,
  onChange,
  toFixed = 2,
  max,
  min,
  ...inputProps
}: FormInputFloatProps) => {
  const [tempState, setTempState] = useState(value.toString())
  useEffect(() => {
    setTempState(value.toString())
  }, [value])
  return (
    <FormInput
      value={tempState}
      onChange={e => {
        const inputValue = e.target.value
        const filteredNumber = inputValue.replace(',', '.')
        const regex = new RegExp(`^-?\\d*(\\.\\d{0,${toFixed}})?$`)
        const number = filteredNumber.match(regex)
        if (number !== null) {
          setTempState(filteredNumber)
        }
      }}
      onBlur={() => {
        let floatNumber = parseFloat(tempState) || 0
        if (max !== undefined) {
          floatNumber = Math.min(floatNumber, max)
        }
        if (min !== undefined) {
          floatNumber = Math.max(floatNumber, min)
        }
        onChange(floatNumber)
        setTempState(floatNumber.toString())
      }}
      {...inputProps}
    />
  )
}

export default FormInputFloat
