import React, { FC, PropsWithChildren, ReactNode } from 'react'
import { Hint } from 'shared/components/hint'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import WalletIcon from 'shared/icons/wallet-icon'

type PricePlanBaseProps = {
  additionalChild?: ReactNode
}

const PricePlanBase: FC<PropsWithChildren<PricePlanBaseProps>> = ({
  children,
  additionalChild,
}) => {
  const { t } = useLocoTranslation()
  return (
    <div className="flex flex-col lg:gap-2 gap-3">
      <div className="flex lg:flex-row flex-col justify-between lg:items-center lg:gap-0 gap-3">
        <div className="flex flex-col gap-3 bo-color-[#F8FAFC]">
          <div className="flex flex-row gap-1 font-normal lg:text-[15px] text-sm text-darkblue items-center">
            <WalletIcon className={'fill-darkblue w-6 h-6 scale-75'} />
            <Hint label={t('dashboard.funnel.configuration.offer.price_plans_description')}>
              {t('dashboard.funnel.configuration.offer.price_plans')}
            </Hint>
          </div>
        </div>
        {additionalChild}
      </div>
      {children}
    </div>
  )
}

export default PricePlanBase
