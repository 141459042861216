import { PricePlanType } from 'modules/digital-product/price-plans/types/price-plan-interface'
import { CurrencyEnum } from 'shared/enums/currency-enum'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useSWR from 'swr'

export function useUserPricePlans(currency?: CurrencyEnum) {
  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, PricePlanType[]>({
    method: RequestMethodsEnum.get,
  })

  const { data: userPricePlans, mutate: mutateUserPricePlans } = useSWR(() => {
    if (currency) {
      return `/api/dashboard/customer/price-plans/${currency}`
    }
    return null
  }, fetcher)

  return { userPricePlans, mutateUserPricePlans }
}
