import {
  DigitalProductChangeInterface,
  DigitalProductErrorInterface,
} from 'modules/digital-product/types/digital-product-interface'

export const defaultDigitalProductValues: DigitalProductChangeInterface = {
  name: '',
  resources: [],
  pricePlans: [],
  confirmationEmail: null,
}

export const defaultDigitalProductErrors: DigitalProductErrorInterface = {
  name: '',
  resources: [],
  pricePlans: [],
  confirmationEmail: {
    subject: '',
    body: '',
    fromName: '',
    fromEmail: '',
    previewText: '',
  },
}
