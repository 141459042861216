import { AnimateLayoutChanges, defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import PricePlanTooltip from 'modules/digital-product/price-plans/components/price-plan-tooltip'
import { PricePlanType } from 'modules/digital-product/price-plans/types/price-plan-interface'
import React, { FC } from 'react'
import { Tooltip } from 'shared/components/tooltip'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import BinIcon from 'shared/icons/bin-icon'
import ChangeIcon from 'shared/icons/change-icon'
import DragIcon from 'shared/icons/drag-icon'
import SettingsIcon from 'shared/icons/settings-icon'

interface PricePlanItemProps {
  pricePlan: PricePlanType
  onChange: () => void
  onEdit: () => void
  onDelete: () => void
}

const animateLayoutChanges: AnimateLayoutChanges = args =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true })
const PricePlanItem: FC<PricePlanItemProps> = ({ pricePlan, onChange, onEdit, onDelete }) => {
  const { t } = useLocoTranslation()
  const { attributes, listeners, setNodeRef, isDragging, transform } = useSortable({
    id: pricePlan.id,
    animateLayoutChanges,
  })

  const dndStyle = {
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.5 : undefined,
  }

  return (
    <div
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      className={'flex items-center gap-3'}
      style={dndStyle}
    >
      <DragIcon />
      <div className="flex justify-between items-center rounded-lg border-gray/30 border p-2 w-full">
        <div className="flex flex-col gap-3 bo-color-[#F8FAFC]">
          <PricePlanTooltip
            pricePlan={pricePlan}
            label={
              <div className="flex flex-row gap-1 lg:text-[15px] text-sm text-blue items-center">
                <span>{pricePlan.innerName || 'test.price.plan.name'}</span>
              </div>
            }
          />
        </div>
        <div className="flex gap-2 items-center">
          <Tooltip
            mode={'hover'}
            label={t('dashboard.funnel.configuration.common.price_plan.change_title')}
          >
            <ChangeIcon
              className="fill-[#CBD5E1] cursor-pointer hover:fill-darkblue w-[17px] h-[16px]"
              onClick={onChange}
            />
          </Tooltip>
          <Tooltip
            mode={'hover'}
            label={t('dashboard.funnel.configuration.common.price_plan.edit_title')}
          >
            <SettingsIcon
              className="fill-[#CBD5E1] cursor-pointer hover:fill-darkblue w-[17px] h-[16px]"
              onClick={onEdit}
            />
          </Tooltip>
          <BinIcon className="cursor-pointer w-[17px] h-[18px]" onClick={onDelete} />
        </div>
      </div>
    </div>
  )
}

export default PricePlanItem
