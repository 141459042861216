import { PricePlanTypeEnum } from 'modules/digital-product/price-plans/enums/price-plan-type-enum'
import {
  PricePlanCreateErrorsInterface,
  PricePlanCreateInterface,
} from 'modules/digital-product/price-plans/types/price-plan-interface'
import {
  getPricePlansTypesData,
  getSubscriptionIntervalData,
  isLimitedSubscription,
} from 'modules/digital-product/price-plans/utils/price-plans-utils'
import { SubscriptionIntervalsEnum } from 'modules/payment/transactions/enums/subscription-intervals-enum'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { PrimaryButton } from 'shared/components/button'
import ButtonLikeLink from 'shared/components/button-like-link'
import FormInput, { handleNumberInput } from 'shared/components/form-input'
import FormInputFloat from 'shared/components/form-input/form-input-float'
import FormSelect from 'shared/components/form-select'
import FormSelectInline from 'shared/components/form-select/form-select-inline'
import { FieldErrorAndDescription } from 'shared/components/form/field-error-and-description'
import { Hint } from 'shared/components/hint'
import { CurrencyEnum } from 'shared/enums/currency-enum'
import { BadRequest } from 'shared/errors/bad-request'
import { InternalError } from 'shared/errors/internal-error'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import CloseIcon from 'shared/icons/close-icon'
import SaveIcon from 'shared/icons/save-icon'

interface CreatePricePlanProps {
  onCreatePricePlan: (value: PricePlanCreateInterface) => Promise<void>
  openAddForm: () => void
  closeForm: () => void
  createErrors: PricePlanCreateErrorsInterface
  currency: CurrencyEnum
}

const defaultValues: PricePlanCreateInterface = {
  name: '',
  innerName: '',
  statementDescriptor: '',
  statementDescriptorKatakana: undefined,
  type: PricePlanTypeEnum.one_shot,
  amount: 0,
  recurringOptions: {
    interval: SubscriptionIntervalsEnum.day,
    intervalCount: 0,
    trialPeriod: 0,
    trialInterval: SubscriptionIntervalsEnum.day,
    limitOfPayments: undefined,
  },
}

function CreatePricePlan({
  onCreatePricePlan,
  openAddForm,
  closeForm,
  createErrors,
  currency,
}: CreatePricePlanProps) {
  const { t } = useLocoTranslation()
  const [tempState, setTempState] = useState(defaultValues)
  const [isFetching, setIsFetching] = useState(false)
  const [error, setError] = useState('')

  const handleConfirm = async () => {
    try {
      const { recurringOptions, ...pricePlanBaseData } = tempState
      pricePlanBaseData.amount = Math.round(pricePlanBaseData.amount * 100)
      const isOneShot = pricePlanBaseData.type === PricePlanTypeEnum.one_shot
      setError('')
      setIsFetching(true)
      await onCreatePricePlan(
        isOneShot ? pricePlanBaseData : { ...pricePlanBaseData, recurringOptions },
      )
      setIsFetching(false)
      closeForm()
    } catch (e) {
      setIsFetching(false)
      if (e instanceof BadRequest) {
        if (e.errors.common) {
          setError(e.errors.common.join(''))
        }
      } else if (e instanceof InternalError) {
        setError(t('core.error.internal_error_message'))
      } else {
        toast.error(t('global.error'))
      }
    }
  }

  return (
    <div className="flex flex-col gap-5 relative rounded-lg border-gray/30 border py-3 px-5">
      <CloseIcon
        className={`cursor-pointer ${
          isFetching && 'fill-gray pointer-events-none'
        } absolute lg:top-2 top-1 lg:right-2 right-1`}
        onClick={() => closeForm()}
      />
      <div className={'flex flex-col lg:flex-row items-end gap-5'}>
        <FormInput
          label={t('dashboard.funnel.configuration.common.price_plan.name_label')}
          placeholder={t('global.name')}
          className={'w-full'}
          value={tempState.name}
          onChange={e =>
            setTempState(prev => {
              return { ...prev, name: e.target.value }
            })
          }
          maxLength={256}
          error={createErrors.name}
        />
        <FormInput
          label={t('dashboard.funnel.configuration.common.price_plan.inner_name_label')}
          placeholder={t('global.name')}
          className={'w-full'}
          value={tempState.innerName}
          onChange={e =>
            setTempState(prev => {
              return { ...prev, innerName: e.target.value }
            })
          }
          maxLength={256}
          error={createErrors.innerName}
        />
      </div>
      <FormInput
        label={
          <Hint
            label={t(
              'dashboard.funnel.configuration.common.price_plan.statement_descriptor_popover',
            )}
          >
            {t('dashboard.funnel.configuration.common.price_plan.statement_descriptor')}
          </Hint>
        }
        placeholder={t('dashboard.funnel.configuration.common.price_plan.statement_descriptor')}
        value={tempState.statementDescriptor}
        onChange={e =>
          setTempState(prev => {
            return { ...prev, statementDescriptor: e.target.value }
          })
        }
        maxLength={22}
        error={createErrors.statementDescriptor}
      />
      {currency === CurrencyEnum.JAPANESE_YEN && (
        <FormInput
          label={
            <Hint
              label={t(
                'dashboard.funnel.configuration.common.price_plan.statement_descriptor_katakana_popover',
              )}
            >
              {t('dashboard.funnel.configuration.common.price_plan.statement_descriptor_katakana')}
            </Hint>
          }
          placeholder={t(
            'dashboard.funnel.configuration.common.price_plan.statement_descriptor_katakana',
          )}
          value={tempState.statementDescriptorKatakana}
          onChange={e =>
            setTempState(prev => {
              return { ...prev, statementDescriptorKatakana: e.target.value }
            })
          }
          maxLength={22}
          error={createErrors.statementDescriptorKatakana}
        />
      )}
      <FormSelect
        withoutCloseIcon
        label={t('dashboard.funnel.configuration.common.price_plan.type')}
        data={getPricePlansTypesData(t)}
        onChange={data =>
          setTempState(prev => {
            return { ...prev, type: data }
          })
        }
        value={tempState.type}
        error={createErrors.type}
      />
      {tempState.type === PricePlanTypeEnum.one_shot && (
        <FormInputFloat
          label={t('dashboard.funnel.configuration.common.price_plan.direct_charge_amount')}
          className={'max-w-[200px]'}
          onChange={value =>
            setTempState(prev => {
              return {
                ...prev,
                amount: value,
              }
            })
          }
          addonAfter={currency}
          value={tempState.amount}
          error={createErrors.amount}
        />
      )}
      {(tempState.type === PricePlanTypeEnum.limited_subscription ||
        tempState.type === PricePlanTypeEnum.subscription) && (
        <>
          <div className="flex items-end gap-5">
            <FormInput
              className={'max-w-[100px]'}
              label={t(
                'dashboard.funnel.configuration.common.price_plan.subscription_plan.trial_period',
              )}
              onChange={e =>
                setTempState(prev => {
                  return {
                    ...prev,
                    recurringOptions: {
                      ...prev.recurringOptions,
                      trialPeriod: handleNumberInput(e.target.value),
                    },
                  }
                })
              }
              value={tempState.recurringOptions?.trialPeriod}
              maxLength={256}
              error={createErrors.recurringOptions.trialPeriod}
            />
            <FormSelectInline
              className={'mb-[12px]'}
              onChange={interval =>
                setTempState(prev => {
                  return {
                    ...prev,
                    recurringOptions: {
                      ...prev.recurringOptions,
                      trialInterval: interval,
                    },
                  }
                })
              }
              withoutCloseIcon
              value={tempState.recurringOptions?.trialInterval}
              data={getSubscriptionIntervalData(t)}
              error={createErrors.recurringOptions.trialInterval}
            />
          </div>
          <div className="flex flex-row flex-wrap gap-5 items-end">
            <FormInputFloat
              label={t(
                'dashboard.funnel.configuration.common.price_plan.subscription_plan.customers_will_pay',
              )}
              className={'max-w-[200px]'}
              onChange={value =>
                setTempState(prev => {
                  return {
                    ...prev,
                    amount: value,
                  }
                })
              }
              addonAfter={currency}
              value={tempState.amount}
              error={createErrors.amount}
            />
            <span className="mb-[12px]">{t('global.each')}</span>
            <FormInput
              className={'max-w-[100px]'}
              onChange={e =>
                setTempState(prev => {
                  return {
                    ...prev,
                    recurringOptions: {
                      ...prev.recurringOptions,
                      intervalCount: handleNumberInput(e.target.value),
                    },
                  }
                })
              }
              value={tempState.recurringOptions?.intervalCount}
              maxLength={256}
              error={createErrors.recurringOptions.intervalCount}
            />
            <FormSelectInline
              className={'mb-[12px]'}
              withoutCloseIcon
              onChange={interval =>
                setTempState(prev => {
                  return {
                    ...prev,
                    recurringOptions: {
                      ...prev.recurringOptions,
                      interval: interval,
                    },
                  }
                })
              }
              value={tempState.recurringOptions?.interval}
              data={getSubscriptionIntervalData(t)}
              error={createErrors.recurringOptions.interval}
            />
          </div>
        </>
      )}
      {isLimitedSubscription(tempState.type as PricePlanTypeEnum) && (
        <FormInput
          key="price-plan-limit-of-payments"
          className="max-w-[150px]"
          label={t(
            'dashboard.funnel.configuration.common.price_plan.subscription_plan.limit_of_payments',
          )}
          onChange={e =>
            setTempState(prev => {
              return {
                ...prev,
                recurringOptions: {
                  ...prev.recurringOptions,
                  limitOfPayments: handleNumberInput(e.target.value),
                },
              }
            })
          }
          value={tempState.recurringOptions?.limitOfPayments}
          maxLength={256}
          error={createErrors.recurringOptions.limitOfPayments}
        />
      )}
      <div
        className={
          'flex lg:flex-row flex-col w-full lg:text-[15px] text-sm text-darkblue font-medium gap-2'
        }
      >
        <span>
          {t('dashboard.funnel.configuration.common.price_plan.create_price_plan_footer')}
        </span>
        <ButtonLikeLink
          onClick={() => {
            openAddForm()
          }}
        >
          {t('dashboard.funnel.configuration.common.price_plan.create_price_plan_footer_link')}
        </ButtonLikeLink>
      </div>
      <div className="flex ">
        <PrimaryButton
          width="large"
          onClick={e => {
            e.preventDefault()
            handleConfirm()
          }}
          isFetching={isFetching}
          type="submit"
        >
          <SaveIcon />
          {t('global.save')}
        </PrimaryButton>
      </div>
      <FieldErrorAndDescription error={error} errorClassName={'text-center mt-2'} />
    </div>
  )
}

export default CreatePricePlan
