import React, { FC } from 'react'
import AddIcon from 'shared/icons/add-icon'

type Props = {
  onClick: () => void
  caption?: string
}

export const AddItemIcon: FC<Props> = ({ onClick, caption }) => (
  <button onClick={onClick} className="flex gap-3 items-center outline-none w-fit">
    <AddIcon className={`fill-blue w-[18px] h-[18px] cursor-pointer`} />
    {caption && <span className="lg:hidden text-blue">{caption}</span>}
  </button>
)
