import React from 'react'

function LinkIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4388 7.42975C9.94619 7.92232 9.14741 7.92232 8.65484 7.42975C8.16227 6.93718 8.16227 6.1384 8.65484 5.64583L12.223 2.0775C14.1937 0.106979 17.3883 0.106979 19.3589 2.0775C21.3294 4.04818 21.3294 7.24282 19.3589 9.21342L15.7909 12.7814C15.2983 13.274 14.4996 13.274 14.007 12.7814C13.5143 12.2888 13.5143 11.4901 14.007 10.9973L17.575 7.4295C18.5602 6.44416 18.5602 4.84668 17.575 3.8615C16.5897 2.87616 14.9922 2.87616 14.007 3.8615L10.4388 7.42975ZM10.4391 14.5653C10.9316 14.0727 11.7304 14.0727 12.223 14.5653C12.7157 15.058 12.7157 15.8566 12.223 16.3493L8.65483 19.9175C6.68431 21.888 3.48951 21.888 1.51891 19.9175C-0.451614 17.947 -0.451614 14.7522 1.51891 12.7816L5.0869 9.21359C5.57964 8.72102 6.37825 8.72102 6.87099 9.21359C7.36356 9.70632 7.36356 10.5051 6.87099 10.9977L3.30298 14.5657C2.31764 15.5508 2.31764 17.1483 3.30298 18.1337C4.28816 19.1189 5.88565 19.1189 6.87099 18.1337L10.4391 14.5653ZM14.0071 7.42934C14.4996 7.92191 14.4996 8.72069 14.0071 9.21326L8.65483 14.5651C8.16226 15.0578 7.36348 15.0578 6.87091 14.5651C6.37834 14.0725 6.37834 13.2737 6.87091 12.7812L12.2227 7.42934C12.7155 6.93661 13.5141 6.93661 14.0068 7.42934H14.0071Z"
      />
    </svg>
  )
}

export default LinkIcon
