import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DropAnimation,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  defaultDropAnimationSideEffects,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import React, { PropsWithChildren, useState } from 'react'

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
}

interface SortableProps<T extends { id: number | string }> extends PropsWithChildren {
  draggable: (element: T) => JSX.Element
  items: T[]
  onMove: (activeId: number, activeIndex: number, overIndex: number) => Promise<void>
  disabled?: boolean
}

const Sortable = <T extends { id: number | string }>({
  items,
  onMove,
  draggable,
  children,
  disabled,
}: SortableProps<T>) => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    }),
  )

  const activeElement = items.find(item => item.id === activeId)

  const onDragCancel = async () => {
    setActiveId(null)
  }

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over?.id) {
      const activeIndex = items.findIndex(({ id }) => id === active.id)
      const overIndex = items.findIndex(({ id }) => id === over.id)
      if (activeIndex > -1 && overIndex > -1) {
        await onMove(Number(active.id), activeIndex, overIndex)
      }
    }
    setActiveId(null)
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        setActiveId(active.id)
      }}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy} disabled={disabled}>
        {children}
      </SortableContext>
      {activeId && (
        <DragOverlay dropAnimation={dropAnimationConfig} className="opacity-0 cursor-grabbing">
          {activeElement ? draggable(activeElement) : null}
        </DragOverlay>
      )}
    </DndContext>
  )
}

export default Sortable
